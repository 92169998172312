/* @refresh reload */

import { customElement, noShadowDOM } from 'solid-element'
import { batch, Component, createEffect, createSignal, onCleanup, onMount, Show, untrack } from 'solid-js'
import { createStore, reconcile } from 'solid-js/store'
import {
	CareerListQuery,
	CareerListQueryVariables,
	CareerLocationsQuery,
	CareerLocationsQueryVariables,
	DiscreteFilterInput,
	PostingSorting,
	PostingSortingField,
	RangeFilterInput,
	SortingOrder,
} from '@/shared/generated/graphql'
import { CAREER_LIST, CAREER_LOCATIONS } from '@/shared/queries/career'
import { DropdownData } from '@/shared/components/Multiselect'
import Search, { SearchQuery } from './subcomponents/Search'
import Filter from './subcomponents/Filter'
import List from './subcomponents/List'
import { useGraphQL } from '@/shared/context/GraphQLContext'
import Breadcrumb, { BreadcrumbType } from '../../shared/components/Breadcrumb'
import queryString from 'query-string'
import { trackStore } from '@solid-primitives/deep'
import { DEFAULT_DISTANCE, DEFAULT_PAGE, DEFAULT_PAGE_SIZE, DEFAULT_SORTING } from './shared/filter-configuration'
import { EventBusProvider } from './context/EventBusContext'

export type CareerStore = {
	rangeFilters: RangeFilterInput[]
	filters: DiscreteFilterInput[]
	postings: CareerListQuery['postings']['items']
	facets: CareerListQuery['postings']['facets']
	query: string
	plz: string
	distance: number
	postingsPagination: PaginationControl
	closeLocations: string[]
	sorting: [PostingSorting]
	starting_date: string
	locationsInRange: CareerLocationsQuery['findLocations']
	generateStateQueryParams: () => string
	visuallySelectedFilterOptions: () => { path: string; value: string }[]
}

type PaginationControl = {
	total: number
	page: number
	pageSize: number
	isLoading: boolean
	hasNext: boolean
}

type Props = {
	locations: string[]
	breadcrumb: BreadcrumbType[]
	searchquery?: string
	redirectsearch?: string
	searchheader?: string
	searchsubheader?: string
	jobtitle?: string
	headerlayout?: number
}

const Career: Component<Props> = props => {
	noShadowDOM()

	const newQueryPostings = useGraphQL()
	const newQueryLocations = useGraphQL()
	const [needsHistory, setNeedsHistory] = createSignal(true)

	const textToTimestap = (timeframe: string): { min: number | null; max: number | null } => {
		switch (timeframe) {
			case 'sofort': {
				return {
					min: null,
					max: Math.trunc(new Date().getTime() / 1000),
				}
			}
			case '3monate': {
				return {
					min: null,
					max: Math.trunc(new Date(new Date().setMonth(new Date().getMonth() + 3)).getTime() / 1000),
				}
			}
			case '3-6monate': {
				return {
					min: Math.trunc(new Date(new Date().setMonth(new Date().getMonth() + 3)).getTime() / 1000),
					max: Math.trunc(new Date(new Date().setMonth(new Date().getMonth() + 6)).getTime() / 1000),
				}
			}
			case '6monate': {
				return {
					min: Math.trunc(new Date(new Date().setMonth(new Date().getMonth() + 6)).getTime() / 1000),
					max: null,
				}
			}
			default: {
				return {
					min: null,
					max: null,
				}
			}
		}
	}

	const getQueryParams = (): {
		filters?: string | { [key: string]: string[] }
		sorting?: string | { [key: string]: string }
		search?: string
		page?: string
		pageSize?: string
		plz?: string
		distance?: string
		starting_date?: string
	} => {
		const pick: {
			filters?: string | { [key: string]: string[] }
			sorting?: string | { [key: string]: string }
			search?: string
			page?: string
			pageSize?: string
			plz?: string
			distance?: string
			starting_date?: string
		} = queryString.parse(window.location.search)
		const filters: string = pick.filters as string
		pick.filters = {} as { [key: string]: string[] }
		if (filters?.length > 0) {
			filters?.split('&').map((filter: string) => {
				const [path, options] = filter.split('=')
				pick.filters = {
					...(pick.filters as { [key: string]: string[] }),
					[path]: options.split(','),
				} as { [key: string]: string[] }
			})
		}
		const sorting: string = pick.sorting as string
		pick.sorting = {}
		if (sorting?.length > 0) {
			sorting?.split('&').map((sort: string) => {
				const [field, order] = sort.split('=')
				pick.sorting = {
					...(pick.sorting as { [key: string]: string }),
					[field]: order,
				}
			})
		}
		return pick
	}

	const ensureValidSorting = (plz: string, query: string) => {
		// If the user searches for the first time, after the search field is empty, we preselect the relevance sorting
		if (store.query === '' && query !== '') {
			setStore('sorting', [{ field: PostingSortingField.Relevance, order: SortingOrder.Descending }])
		}

		// Check for invalid sorting configurations
		if (query === '' && store.sorting[0].field === PostingSortingField.Relevance) {
			setStore('sorting', [DEFAULT_SORTING])
		}

		if (plz === '' && store.sorting[0].field === PostingSortingField.Distance) {
			setStore('sorting', [DEFAULT_SORTING])
		}
	}

	const resetPaginationPage = () => {
		setStore('postingsPagination', 'page', 0)
	}

	const onSortingChange = (field: string) => {
		let direction

		switch (field) {
			case 'relevance':
				direction = SortingOrder.Descending
				break
			case 'crdate':
				direction = SortingOrder.Descending
				break
			case 'starting_date':
				direction = SortingOrder.Ascending
				break
			case 'distance':
				direction = SortingOrder.Ascending
				break
			default: {
				field = 'crdate'
				direction = SortingOrder.Descending
			}
		}

		setStore('sorting', [{ field: field as PostingSortingField, order: direction }])
		resetPaginationPage()
	}

	const [store, setStore] = createStore<CareerStore>({
		filters: [
			{
				path: 'categories.title',
				options: [],
			},
			{
				path: 'career_level',
				options: [],
			},
			{
				path: 'employment_type',
				options: [],
			},
			{
				path: 'employment_form',
				options: [],
			},
			{
				path: 'locations.name',
				options: [],
			},
			{
				path: 'source.name_short',
				options: [],
			},
		],
		rangeFilters: [],
		postings: [],
		closeLocations: [],
		facets: [],
		// eslint-disable-next-line solid/reactivity
		query: props.searchquery ?? '',
		plz: '',
		distance: DEFAULT_DISTANCE,
		starting_date: '',
		postingsPagination: {
			total: 0,
			page: DEFAULT_PAGE,
			pageSize: DEFAULT_PAGE_SIZE,
			isLoading: true,
			hasNext: false,
		},
		sorting: [DEFAULT_SORTING],
		locationsInRange: [],
		generateStateQueryParams: (): string => {
			let querySortingOrder = queryString.stringify(store.sorting[0], {
				arrayFormat: 'comma',
			})
			if (
				querySortingOrder ===
				queryString.stringify(DEFAULT_SORTING, {
					arrayFormat: 'comma',
				})
			) {
				querySortingOrder = ''
			}

			let queryPageSize = store.postingsPagination.pageSize.toString()
			if (queryPageSize === DEFAULT_PAGE_SIZE.toString()) {
				queryPageSize = ''
			}

			let queryDistance = store.distance.toString()
			if (queryDistance === DEFAULT_DISTANCE.toString()) {
				queryDistance = ''
			}

			let queryPage = store.postingsPagination.page.toString()
			if (queryPage === DEFAULT_PAGE.toString()) {
				queryPage = ''
			}

			// generate object with all none career params
			const params = getQueryParams()
			// list of career params to exclude from none career params
			const careerrParams = ['filters', 'sorting', 'search', 'page', 'pageSize', 'plz', 'distance', 'starting_date']
			// object with none career params
			let noneCareerParams = Object.keys(params)
				.filter(key => !careerrParams.includes(key))
				.reduce((obj: any, key: string) => {
					obj[key] = params[key as keyof Object]
					return obj
				}, {})

			return queryString.stringify(
				{
					filters: queryString.stringify(
						Object.fromEntries(store.filters.map(filter => [filter.path, filter.options?.map(option => option)])),
						{ arrayFormat: 'comma' }
					),
					sorting: querySortingOrder,
					search: store.query,
					page: queryPage,
					pageSize: queryPageSize,
					plz: store.plz,
					distance: queryDistance,
					starting_date: store.starting_date.toLowerCase(),
					// merge none career params, empty safe
					...{ ...noneCareerParams },
				},
				{ skipEmptyString: true, skipNull: true }
			)
		},
		// eslint-disable-next-line solid/reactivity
		visuallySelectedFilterOptions: () => {
			let returnArray: ReturnType<CareerStore['visuallySelectedFilterOptions']> = []
			if (store.starting_date !== '') {
				returnArray.push({ path: 'starting_date', value: store.starting_date })
			}
			returnArray = returnArray.concat(
				store.filters.flatMap(filter => filter.options?.map(option => ({ path: filter.path, value: option })) ?? [])
			)
			// remove cities temporarily
			returnArray = returnArray.filter((item, _index, _self) => {
				return !(item.path === 'locations.name' && store.locationsInRange.some(x => x.name === item.value))
			})
			return returnArray
		},
	})

	const setFilterStateFromQueryParams = () => {
		batch(() => {
			const pick = getQueryParams()

			let searchQuery = ''
			if (props.searchquery && needsHistory()) {
				searchQuery = props.searchquery
			} else {
				searchQuery = pick.search ?? ''
			}

			ensureValidSorting(pick.plz ?? '', searchQuery)

			setStore('query', searchQuery)

			setStore('plz', pick.plz ?? '')

			setStore('distance', pick.distance ? parseInt(pick.distance) : 1)

			// Check if the sorting field is valid
			const sortingInput: PostingSorting | undefined = pick.sorting as unknown as PostingSorting
			if (pick.sorting && sortingInput?.field && sortingInput?.order) {
				setStore('sorting', pick.sorting ? [pick.sorting as unknown as PostingSorting] : [DEFAULT_SORTING])
			}
			const pickFilters: { [key: string]: string[] } = pick.filters as { [key: string]: string[] }

			store?.filters?.forEach((filter: DiscreteFilterInput) => {
				if (!pickFilters[filter.path]) {
					pickFilters[filter.path] = []
				}
			})

			for (const path of Object.keys(pick.filters as { [key: string]: string[] })) {
				const filter = store.filters.find(filter => filter.path === path)
				if (!filter) {
					break
				}

				setStore(
					'filters',
					store.filters.findIndex((filter: DiscreteFilterInput) => filter.path === path),
					'options',
					pickFilters[path].map((x: string) => decodeURIComponent(x)) ?? []
				)
			}

			setStore('starting_date', pick.starting_date ? pick.starting_date : '')
			setStore('postingsPagination', 'pageSize', pick.pageSize ? parseInt(pick.pageSize) : 10)
			setStore('postingsPagination', 'page', pick.page ? parseInt(pick.page) : 0)

			if (pick.plz && pick.plz.length && store.filters.find(x => x.path === 'locations.name')?.options?.length === 0) {
				setStore('plz', pick.plz)
			}
		})
	}

	// eslint-disable-next-line solid/reactivity
	setFilterStateFromQueryParams()

	const locationsArguments = () => {
		if (!store.plz) {
			return undefined
		}

		return {
			query: store.plz,
			radius: store.distance,
		}
	}

	// eslint-disable-next-line solid/reactivity
	const [locationsResponse] = newQueryLocations<CareerLocationsQuery, CareerLocationsQueryVariables>(
		CAREER_LOCATIONS,
		locationsArguments
	)

	// This handles the special case for the special plz location search filter bubble
	const resetLocationSearch = () => {
		let locationsInRange: CareerStore['locationsInRange'] = []
		let searchQuery = ''
		untrack(() => {
			locationsInRange = store.locationsInRange
			searchQuery = store.query
		})

		batch(() => {
			setStore('plz', '')
			setStore('distance', DEFAULT_DISTANCE)
			ensureValidSorting('', searchQuery)
			// Compare the locationsInRange with the filters currently selected and remove all the ones that in locationsInRange
			const newOptions = store.filters
				.find(x => x.path === 'locations.name')
				?.options?.map(x => x)
				.filter(option => !locationsInRange.find(x => x.name === option))
			setStore('filters', filters => filters.path === 'locations.name', 'options', newOptions)
			setStore('locationsInRange', [])
			resetPaginationPage()
		})
	}

	const careerListArguments = () => {
		if (!locationsResponse && store.plz !== '') {
			return undefined
		}

		trackStore(store.filters)

		return {
			discreteFilters: store.filters,
			closeLocations: store.filters.find(x => x.path === 'locations.name')?.options ?? [],
			offset: store.postingsPagination.page * store.postingsPagination.pageSize,
			first: store.postingsPagination.pageSize,
			query: store.query,
			// We only need plz and distance to be able to sort by distance
			plz: store.plz,
			distance: store.distance,
			sorting: store.sorting,
			rangeFilters: [
				{
					path: 'starting_date',
					range: {
						min: store.starting_date.length > 0 ? textToTimestap(store.starting_date).min : null,
						max: store.starting_date.length > 0 ? textToTimestap(store.starting_date).max : null,
					},
				},
			],
		}
	}

	// eslint-disable-next-line solid/reactivity
	const [postingsData] = newQueryPostings<CareerListQuery, CareerListQueryVariables>(CAREER_LIST, careerListArguments)

	const [initialFacets, setInitialFacets] = createSignal<CareerListQuery['postings']['facets']>([])

	// React to loading state values in store
	createEffect(() => {
		setStore('postingsPagination', 'isLoading', postingsData.loading || postingsData.state === 'unresolved')
		untrack(() => {
			if (postingsData.loading) {
				return
			}
			if (!needsHistory()) {
				setNeedsHistory(true)
			} else {
				setQueryParams()
			}
		})
	})

	createEffect(() => {
		locationsResponse()
		untrack(() => {
			if (store.plz !== '') {
				setStore('locationsInRange', locationsResponse()?.findLocations ?? [])
				setStore(
					'filters',
					filter => filter.path === 'locations.name',
					'options',
					locationsResponse()?.findLocations.map(x => x.name!) ?? []
				)
			}
		})
	})

	// React to request data and fill store
	createEffect(() => {
		if (postingsData()) {
			setStore('postings', reconcile(postingsData()?.postings?.items ?? []))
			setStore('facets', reconcile(postingsData()?.postings?.facets ?? []))
			setStore('closeLocations', reconcile(postingsData()?.closeLocations?.map(x => x.name!) ?? []))
			setStore('postingsPagination', 'total', postingsData()?.postings?.totalCount ?? 0)
			setStore('postingsPagination', 'hasNext', postingsData()?.postings?.pageInfo.hasNextPage ?? false)
		}
		if (initialFacets.length === 0) {
			setInitialFacets(store?.facets ?? [])
		}
		setStore('facets', reconcile(postingsData()?.postings?.facets.concat([]) ?? []))
	})

	const setQueryParams = () => {
		const query = store.generateStateQueryParams()
		const url = new URL(window.location.href)
		url.search = query
		if (window.location.href === url.toString()) {
			return
		}
		window.history.pushState({}, '', url.toString())
	}

	const onSearch = (data: SearchQuery) => {
		// Update the filters in place
		batch(() => {
			// If the plz is empty, reset the location search, because the selected locations should not be selected anymore
			if (data.plz === '') {
				resetLocationSearch()
			}

			ensureValidSorting(data.plz, data.query)

			setStore('query', reconcile(data.query))
			setStore('plz', reconcile(data.plz))
			setStore('distance', reconcile(data.distance))
			setStore('filters', x => x.path === 'categories.title', 'options', reconcile(data.categories))

			resetPaginationPage()
		})
	}

	const onPageChange = (page: number) => {
		setStore('postingsPagination', 'page', page)
		document.getElementById('career-list-header')?.scrollIntoView({ behavior: 'smooth' })
	}

	const onFilterOptionDisabled = (path: string, value: string) => {
		batch(() => {
			setStore('postingsPagination', 'page', 0)
			if (path === 'query') {
				setStore('query', '')
				return
			}

			if (path === 'plz') {
				setStore('plz', '')
				return
			}

			if (path === 'distance') {
				setStore('distance', 1)
				return
			}

			if (path === 'starting_date') {
				setStore('starting_date', '')
				return
			}

			setStore(
				'filters',
				filter => filter.path === path,
				'options',
				options => options?.filter(option => option !== value) ?? []
			)
		})
	}

	const onResetFilters = () => {
		batch(() => {
			setStore('query', '')
			setStore('sorting', [DEFAULT_SORTING])
			setStore('plz', '')
			setStore('distance', 1)
			setStore('filters', filters => filters.map(filter => ({ ...filter, options: [] })))
			setStore('starting_date', '')
			resetLocationSearch()
			resetPaginationPage()
		})
	}

	const setFilter = (path: string, options: DropdownData[]) => {
		// Check if all options of the locationRange were deselected, if so we also need to clear the location search
		if (path === 'locations.name') {
			const optionsInRange = options.map(x => x.value).filter(x => store.locationsInRange.some(y => y.name === x))
			if (optionsInRange.length === 0 && store.locationsInRange.length > 0) {
				resetLocationSearch()
				return
			}
		}

		batch(() => {
			setStore('postingsPagination', 'page', 0)
			setStore(
				'filters',
				filter => filter.path === path,
				'options',
				options.map(x => x.value)
			)
		})
	}

	const setStartingDateFilter = (path: string, options: DropdownData[]) => {
		setStore('starting_date', options?.length > 0 ? options[0].value : '')
		resetPaginationPage()
	}

	const [mobile, isMobile] = createSignal(false)
	const handlerResize = () => {
		if (window.matchMedia('(max-width: 992px)').matches) {
			isMobile(true)
		} else {
			isMobile(false)
		}
	}
	const handlePopstate = () => {
		setNeedsHistory(false)
		setFilterStateFromQueryParams()
	}

	onMount(() => {
		window.addEventListener('resize', handlerResize)
		window.addEventListener('load', handlerResize)
		window.addEventListener('popstate', handlePopstate)
	})

	onCleanup(() => {
		window.removeEventListener('resize', handlerResize)
		window.removeEventListener('load', handlerResize)
		window.removeEventListener('popstate', handlePopstate)
	})

	return (
		<EventBusProvider>
			<div id="career" class="career">
				<Show when={mobile() && !props.redirectsearch}>
					<Breadcrumb breadcrumb={props.breadcrumb} />
				</Show>
				<Search
					store={store}
					onSearch={onSearch}
					onFilterChange={setFilter}
					redirectsearch={props.redirectsearch}
					searchheader={props.searchheader}
					searchsubheader={props.searchsubheader}
					searchquery={props.searchquery}
					jobtitle={props.jobtitle}
					headerlayout={props.headerlayout}
				/>
				<Show when={!props.redirectsearch}>
					<div class="flex w-full flex-col lg:flex-row">
						<Filter
							onResetLocationSearch={resetLocationSearch}
							store={store}
							onFilterChange={setFilter}
							onRangeFilterChange={setStartingDateFilter}
							onFilterOptionDisabled={onFilterOptionDisabled}
							resetFilter={onResetFilters}
							breadcrumb={props.breadcrumb}
						/>
						<List
							showNoResult={
								// Show no results either if there are no postings or if there are no locations
								(store.postingsPagination.total === 0 && !store.postingsPagination.isLoading) ||
								((locationsResponse()?.findLocations.length ?? -1) === 0 && store.plz != '')
							}
							onResetLocationSearch={resetLocationSearch}
							store={store}
							onPageChange={onPageChange}
							onPageSizeChange={pageSize => {
								setStore('postingsPagination', 'pageSize', pageSize)
								resetPaginationPage()
							}}
							onFilterOptionDisabled={onFilterOptionDisabled}
							onSortingChange={onSortingChange}
							resetFilter={onResetFilters}
						/>
					</div>
				</Show>
			</div>
		</EventBusProvider>
	)
}

customElement<Props>(
	'career-list',
	{
		locations: [],
		breadcrumb: [],
		redirectsearch: '',
		searchheader: '',
		searchsubheader: '',
		searchquery: '',
		jobtitle: '',
		headerlayout: undefined,
	},
	Career
)
