import { Component, For, Show, Switch, Match, createMemo, createSignal, createEffect, onMount, onCleanup } from 'solid-js'
import DropdownMini, { DropdownItem } from '../../../shared/components/DropdownMini'
import ListSkeleton from './ListSkeleton'
import { CareerStore } from '../career-list'
import Pagination from '../../../shared/components/Pagination'
import Posting from '@/components/career-list/subcomponents/Posting'
import ListSimilarPostings from '@/components/career-list/subcomponents/ListSimilarPostings'
import FilterBubbles from './FilterBubbles'

type Props = {
	store: CareerStore
	showNoResult: boolean
	onPageChange: (page: number) => void
	onPageSizeChange: (pageSize: number) => void
	onFilterOptionDisabled: (path: string, value: string) => void
	onSortingChange: (field: string) => void
	resetFilter: () => void
	onResetLocationSearch: () => void
}

const List: Component<Props> = props => {
	const selectedFilterOptions = createMemo(() => props.store.visuallySelectedFilterOptions())

	const selectedLocationFilterOptions = createMemo(() => {
		return props.store.filters.find(x => x.path === 'locations.name')?.options ?? []
	})

	const [mobile, isMobile] = createSignal(false)
	const [tablet, isTablet] = createSignal(false)

	const handlerResize = () => {
		if (window.matchMedia('(min-width: 992px)').matches) {
			isMobile(false)
		} else {
			isMobile(true)
		}

		if (window.matchMedia('(min-width: 768px)').matches) {
			isTablet(true)
		} else {
			isTablet(false)
		}
	}
	onMount(() => {
		window.addEventListener('resize', handlerResize)
		window.addEventListener('load', handlerResize)
	})

	onCleanup(() => {
		window.removeEventListener('resize', handlerResize)
		window.removeEventListener('load', handlerResize)
	})

	return (
		<>
			<div class="career-list">
				<Show
					when={!props.store.postingsPagination.isLoading}
					fallback={<h2 class="mx-auto w-[40%] animate-pulse rounded-full bg-brightgrey lg:mx-0">&nbsp;</h2>}>
					<Switch>
						<Match when={props.showNoResult}>
							<h2 class="career-list-header" id="career-list-header">
								Kein passender Job dabei?
							</h2>
							<p class="subheader">
								Wir haben leider keinen passenden Job gefunden. Du kannst entweder deine Suchanfrage anpassen oder
								es zu einem späteren Zeitpunkt erneut versuchen.
							</p>
						</Match>
						<Match when={props.store.postingsPagination.total > 0}>
							<h2 class="career-list-header" id="career-list-header">
								<Show when={props.store.query === ''}>
									{props.store.postingsPagination.total} Job
									{props.store.postingsPagination.total !== 1 ? 's' : ''} warte
									{props.store.postingsPagination.total !== 1 ? 'n' : 't'} auf dich
								</Show>
								<Show when={props.store.query}>
									Wir haben {props.store.postingsPagination.total} Job
									{props.store.postingsPagination.total !== 1 ? 's' : ''}&nbsp;für {props.store.query} gefunden
								</Show>
							</h2>
						</Match>
						<Match when={true}>Es ist ein Fehler aufgetreten. Bitte laden Sie die Seite neu.</Match>
					</Switch>
				</Show>
				<Show when={!mobile()}>
					<div class="flex flex-row flex-wrap gap-4">
						<FilterBubbles
							onResetLocationSearch={props.onResetLocationSearch}
							store={props.store}
							onFilterOptionDisabled={props.onFilterOptionDisabled}
						/>
					</div>
				</Show>
				<Show
					when={!props.store.postingsPagination.isLoading}
					fallback={
						<div class="career-list-dropdown ml-auto mt-0 w-[30%] animate-pulse rounded-full bg-brightgrey text-sm lg:mt-5">
							&nbsp;
						</div>
					}>
					<div
						class="career-list-dropdown mt-0 text-sm lg:mt-5"
						classList={{
							'justify-between': (selectedFilterOptions().length > 0 || !!props.store.plz) && !mobile(),
							'justify-end': selectedFilterOptions().length === 0 || mobile(),
						}}>
						<Show when={(selectedFilterOptions().length > 0 || !!props.store.plz) && !mobile()}>
							<button
								class="flex gap-2"
								type="button"
								onClick={() => props.resetFilter()}
								disabled={props.store.postingsPagination.isLoading}>
								<span class="tiny-button my-auto">
									<svg aria-hidden="true" viewBox="0 0 1 1">
										<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#close" />
									</svg>
								</span>
								<span class="my-auto leading-none">Alle Filter zurücksetzen</span>
							</button>
						</Show>
						<Show when={props.store.postings.length > 0 && !props.showNoResult}>
							<ul class="result-display">
								<li class="flex">
									<span class="my-auto hidden sm:block">Ergebnisanzeige:</span>
									<DropdownMini
										items={[
											{ name: '5', value: '5' },
											{ name: '10', value: '10' },
											{ name: '20', value: '20' },
											{ name: '30', value: '30' },
										]}
										defaultItemIndex={
											[5, 10, 20, 30].findIndex(x => x === props.store.postingsPagination.pageSize) ?? 1
										}
										onSelect={item => props.onPageSizeChange(parseInt(item.value))}
										disabled={props.store.postingsPagination.isLoading}
									/>
								</li>
								<li class="flex">
									<span class="my-auto hidden sm:block">Sortieren nach:</span>
									<DropdownMini
										items={[
											{ name: 'Relevanz', value: 'relevance', disabled: props.store.query === '' },
											{ name: 'Aktualität', value: 'crdate' },
											{ name: 'Eintrittsdatum', value: 'starting_date' },
											{ name: 'Entfernung', value: 'distance', disabled: props.store.plz === '' },
										]}
										disabled={props.store.postingsPagination.isLoading}
										onSelect={item => props.onSortingChange(item.value)}
										defaultItemIndex={
											['relevance', 'crdate', 'starting_date', 'distance'].findIndex(
												x => x === props.store?.sorting[0]?.field
											) ?? 0
										}
									/>
								</li>
							</ul>
						</Show>
					</div>
				</Show>

				<Show when={!props.showNoResult || props.store.postingsPagination.isLoading}>
					<ul class="joblist">
						<Switch>
							<Match when={props.store.postingsPagination.isLoading}>
								<ListSkeleton pageSize={props.store.postingsPagination.pageSize} />
							</Match>
							<Match when={true}>
								<For each={props.store.postings}>
									{posting => (
										<Posting
											isTablet={tablet()}
											posting={posting}
											selectedLocationFilterOptions={selectedLocationFilterOptions()}
										/>
									)}
								</For>
							</Match>
						</Switch>
					</ul>
					<Show when={props.store.postings.length > 0}>
						<Pagination
							currentPage={props.store.postingsPagination.page}
							totalPageCount={Math.ceil(
								props.store.postingsPagination.total / props.store.postingsPagination.pageSize
							)}
							totalCount={props.store.postingsPagination.total ?? 0}
							pageSize={props.store.postingsPagination.pageSize}
							isLoading={props.store.postingsPagination.isLoading}
							onPageChange={props.onPageChange}
						/>
					</Show>
				</Show>

				<Show when={!props.store.postingsPagination.hasNext}>
					<ListSimilarPostings store={props.store} />
				</Show>
			</div>
		</>
	)
}

export default List
