import { Component, createEffect, createSignal, For, on, Show, untrack } from 'solid-js'
import LoadingSpinner from './LoadingSpinner'
import { useKeyDownEvent, useKeyDownList } from '@solid-primitives/keyboard'
import { debounce, Scheduled } from '@solid-primitives/scheduled'
import IconClear from '@/assets/icons/career/BWEGT_CAREER_CLEAR.svg'

interface SearchBarProps {
	// The name of the field in the form
	name: string
	id?: string
	class?: string
	inputClass?: string
	placeholder?: string
	value?: string

	isRequired?: boolean
	isFirstEntryInitiallyFocused?: boolean

	error?: string

	alwaysSelectFirstItem?: boolean

	items: SearchableItem[]

	loading: boolean

	'aria-listbox-label': string

	onSearch?: (query: string, suggest: boolean) => void
	onSelect?: (item: SearchableItem) => void
	onSubmit?: (e: Event, query: string) => void
}

interface SearchableItem {
	name: string
	id: string
}

const SearchBar: Component<SearchBarProps> = props => {
	const [hasFocus, setHasFocus] = createSignal(false)
	const [isOpen, setIsOpen] = createSignal(false)

	// eslint-disable-next-line solid/reactivity
	const [focusedItemIndex, setFocusedItemIndex] = createSignal<number>(props.isFirstEntryInitiallyFocused ? 0 : -1)
	const focusedItem: () => SearchableItem | undefined = () => props.items[focusedItemIndex()] ?? undefined
	// eslint-disable-next-line solid/reactivity
	const [searchTerm, setSearchTerm] = createSignal(props.value ?? '')
	const [selectedItem, setSelectedItem] = createSignal<SearchableItem | null>(null)

	const [searchBarElement, setSearchBarElement] = createSignal<HTMLDivElement | null>(null)
	const [inputElement, setInputElement] = createSignal<HTMLInputElement | null>(null)

	const keys = useKeyDownList()
	const keyEvent = useKeyDownEvent()

	// Generate unique id for the input field
	// eslint-disable-next-line solid/reactivity
	const id = props.id ?? Math.random().toString(36).substr(2, 9)

	createEffect(
		on(
			() => props.value,
			() => {
				if (props.value !== searchTerm()) {
					setSelectedItem(null)
					setFocusedItemIndex(-1)
					setIsOpen(false)
					setSearchTerm(props.value ?? '')
					props.onSearch?.(searchTerm(), true)
				}
			}
		)
	)

	const onSelect = (event: MouseEvent, item: SearchableItem) => {
		event.preventDefault()
		event.stopImmediatePropagation()

		setSelectedItem(item)
		setFocusedItemIndex(-1)
		setSearchTerm(inputElement()!.value)
		props.onSearch?.(searchTerm(), false)
		setIsOpen(false)
	}

	const onFocusIn = () => {
		if (props.items.length > 0) {
			setIsOpen(true)
		}
		setHasFocus(true)
	}

	const onFocusOut = (event: FocusEvent) => {
		// Check if the user clicked on the searchBarElement or a child of it
		if (searchBarElement() && searchBarElement()!.contains(event.relatedTarget as Node)) {
			return
		}

		setHasFocus(false)
		setIsOpen(false)
	}

	const scrollToCurrentItem = (behaviour: 'smooth' | 'auto' = 'smooth') => {
		if (!focusedItem()) {
			return
		}
		const item = document.getElementById(`search-bar-listbox-${id}-item-${focusedItem()?.id}`)

		if (item) {
			item.scrollIntoView({ block: 'nearest', behavior: behaviour })
		}
	}

	createEffect(() => {
		if (props.items.length > 0 && hasFocus()) {
			setIsOpen(true)
		} else {
			setIsOpen(false)
		}
	})

	createEffect(() => {
		const focusedItemIndexUntracked = untrack(focusedItemIndex)
		const e = keyEvent()

		// If the user is not focused on the search bar, don't do anything
		if (!hasFocus() || !e) {
			return
		}
		handleInputs(e, focusedItemIndexUntracked)
	})

	const handleInputs: Scheduled<[e: KeyboardEvent, focusedItemIndexUntracked: number]> = debounce(
		// eslint-disable-next-line solid/reactivity
		(e: KeyboardEvent, focusedItemIndexUntracked: number) => {
			if (keys().length !== 1) {
				return
			}
			if (keys()[0] === 'ARROWDOWN' || keys()[0] === 'TAB') {
				if (props.items.length === 0) {
					return
				}
				e!.preventDefault()
				setIsOpen(true)
				const nextIndex = props.items.length - 1 === focusedItemIndexUntracked ? 0 : focusedItemIndexUntracked + 1
				setFocusedItemIndex(nextIndex)
				scrollToCurrentItem()
				return
			}
			if (keys()[0] === 'ARROWUP') {
				e!.preventDefault()
				const prevIndex = focusedItemIndexUntracked === 0 ? props.items.length - 1 : focusedItemIndexUntracked - 1
				setFocusedItemIndex(prevIndex)
				scrollToCurrentItem()
				return
			}
			if (keys()[0] === 'ENTER') {
				e!.preventDefault()
				if (focusedItem()) {
					setSelectedItem(focusedItem()!)
					setFocusedItemIndex(-1)
					setIsOpen(false)
					setSearchTerm(inputElement()!.value)
					return
				}
				setSearchTerm(inputElement()!.value)
				inputElement()?.blur()
				props.onSearch?.(searchTerm(), true)
				props.onSubmit?.(e!, searchTerm())
				return
			}
			if (keys()[0] === 'ESCAPE') {
				e!.preventDefault()
				if (isOpen()) {
					setSelectedItem(null)
					setFocusedItemIndex(-1)
					setIsOpen(false)
					return
				}
				inputElement()?.blur()
			}
		},
		10
	)

	createEffect(() => {
		if (props.items.length > 0 && props.items.length - 1 < focusedItemIndex()) {
			setFocusedItemIndex(props.items.length - 1)
		}
	})

	return (
		<div ref={setSearchBarElement} class={`search-bar ${props.class ?? ''}`}>
			<div class="input-with-spinner">
				<div class="spinner" classList={{ '!right-8': searchTerm().length > 0 }}>
					<LoadingSpinner show={props.loading} />
				</div>
				<Show when={searchTerm().length > 0}>
					<button>
						<IconClear
							onClick={e => {
								e.stopPropagation()
								setSearchTerm('')
								setSelectedItem(null)
								setFocusedItemIndex(-1)
								props.onSearch?.(searchTerm(), true)
							}}
							class="absolute right-4 top-[50%] z-[1] translate-y-[-50%] transform cursor-pointer"
							width="12.262"
							height="12.262"
						/>
					</button>
				</Show>
				<input
					id={props.id ?? ''}
					onFocusIn={onFocusIn}
					ref={setInputElement}
					onBlur={onFocusOut}
					class={`autocomplete__input ${props.inputClass ?? ''}`}
					type="text"
					role="combobox"
					autocomplete="off"
					aria-autocomplete="list"
					placeholder={props.placeholder ?? ''}
					value={selectedItem() ? selectedItem()!.name : searchTerm()}
					onInput={e => {
						setSearchTerm((e.target as HTMLInputElement).value)
						setSelectedItem(null)
						setFocusedItemIndex(-1)
						props.onSearch?.(searchTerm(), true)
					}}
					aria-controls={`search-bar-listbox-${id}`}
					required={props.isRequired ?? false}
					classList={{
						error: !!props.error,
					}}
				/>
			</div>

			<Show when={props.error}>
				<span id={`${props.name}-error`} class="error-dialog js-dialog-error">
					{props.error}
				</span>
			</Show>

			<Show when={isOpen()}>
				<ul
					id={`search-bar-listbox-${id}`}
					class="autocomplete__list"
					role="listbox"
					aria-expanded={hasFocus()}
					aria-label={props['aria-listbox-label']}
					aria-activedescendant={
						focusedItem() !== undefined ? `search-bar-listbox-${id}-item-${focusedItem()!.id}` : ''
					}>
					<For each={props.items}>
						{(item, index) => (
							<li
								id={`search-bar-listbox-${id}-item-${item.id}`}
								class="autocomplete__item"
								classList={{
									focused: focusedItemIndex() === index(),
									selected: (selectedItem() && selectedItem()!.id === item.id) ?? false,
								}}
								aria-label={item.name}
								onMouseDown={event => onSelect(event, item)}
								role="option"
								aria-selected={(selectedItem() && selectedItem()!.id === item.id) ?? false}>
								{item.name}
							</li>
						)}
					</For>
				</ul>
			</Show>
		</div>
	)
}

export default SearchBar
export type { SearchableItem }
