import { Component, createEffect, createSignal, For, onCleanup, onMount, untrack } from 'solid-js'
import { useKeyDownEvent, useKeyDownList } from '@solid-primitives/keyboard'

interface DropdownItem<T> {
	name: string
	value: T
	disabled?: boolean
}

type Props<T> = {
	items: DropdownItem<T>[]
	onSelect?: (item: DropdownItem<T>) => void
	defaultItemIndex?: number
	disabled?: boolean
}
const DropdownMini: Component<Props<string>> = props => {
	let ref!: HTMLDivElement

	const keys = useKeyDownList()

	const [isOpen, setIsOpen] = createSignal(false)

	// eslint-disable-next-line solid/reactivity
	const [selected, setSelected] = createSignal(props.items[props.defaultItemIndex ?? 0])
	createEffect(() => {
		setSelected(props.items[props.defaultItemIndex ?? 0])
	})
	createEffect(() => {
		if (keys().length !== 1) {
			return
		}

		if (keys()[0] === 'ESCAPE') {
			setIsOpen(false)
		}
	})

	const onClick = (e: MouseEvent) => {
		if (ref && !ref.contains(e.target as Node) && isOpen()) {
			setIsOpen(false)
		}
	}

	const onSelect = (item: DropdownItem<string>) => {
		setSelected(item)
		props.onSelect?.(item)
		setIsOpen(false)
	}

	createEffect(() => {
		if (props.items.length === 0) {
			return
		}

		untrack(() => {
			if (!props.items.some(x => x.value === selected().value)) {
				onSelect(props.items[0])
			}
		})
	})

	onMount(() => {
		document.addEventListener('click', onClick)
	})

	onCleanup(() => {
		document.removeEventListener('click', onClick)
	})

	// Generate random id for aria
	const id = Math.random().toString(36).substring(2, 15)

	return (
		<div ref={ref}>
			<div role="listbox" class="relative p-2">
				<a
					id={`dropdown-${id}`}
					itemprop="item"
					aria-haspopup="true"
					role="button"
					aria-expanded={isOpen()}
					class="flex items-center gap-1 border-none hover:border-none"
					title={selected().name}
					aria-controls={`dropdown-${id}-siblings`}
					onClick={() => setIsOpen(!isOpen())}
					aria-disabled={props.disabled ?? false}
					classList={{ 'cursor-default pointer-events-none': props.disabled ?? false }}>
					<span itemprop="name">{selected().name}</span>
					<span class="justify-content-center flex h-[14px] w-[12px] items-center rounded-full !border-none bg-[#eee]">
						<svg width="12px" height="12px" aria-hidden="true" viewBox="0 0 1 1">
							<use href="/typo3conf/ext/basicdistribution/Resources/Public/Icons/Symbols/sprite.symbol.svg#ios-arrow-down" />
						</svg>
					</span>
				</a>

				<ol
					id={`dropdown-${id}`}
					class="absolute left-0 flex flex-col gap-1 bg-white p-2"
					classList={{ hidden: !isOpen() }}
					aria-expanded={isOpen()}
					aria-hidden={!isOpen()}
					aria-labelledby={`dropdown-${id}`}>
					<For each={props.items}>
						{item => (
							<li
								class={
									'border-none hover:border-none' +
									(item.disabled ? ' !cursor-default !text-gray-300' : ' cursor-pointer')
								}>
								<a
									itemprop="name"
									role="listitem"
									class={
										'border-none font-normal hover:border-none' +
										(item.value === selected().value ? ' !cursor-default !font-bold' : '')
									}
									onClick={() => (item.disabled ? null : onSelect(item))}>
									{item.name}
								</a>
							</li>
						)}
					</For>
				</ol>
			</div>
		</div>
	)
}

export default DropdownMini
export type { DropdownItem }
